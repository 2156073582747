'use strict'

import Polyfills from './Polyfills.js'
import { Bartender } from '@fokke-/bartender.js'
import boringmenu from '@teppokoivula/boringmenu'
import Glightbox from 'glightbox'

/**
 * Site class contains general purpose site-specific features.
 *
 * @version 1.1.0
 */
export default class Site {
	/**
	 * Class constructor
	 *
	 * @param {Object} options Options for the class.
	 */
	constructor(options = {}) {
		this.options = {}

		// Init polyfills
		const polyfills = new Polyfills()
		polyfills.init()

		// Init off-canvas after mobile menu is ready
		document.addEventListener('boringmenu-init-done', () => {
			window.bartender = new Bartender({
				el: '.bartender-main',
				contentEl: '.bartender-content',
			}, {
				focusTrap: true,
			})

			window.bartender.addBar('mobileBar', {
				el: '.mobileBar',
				position: 'right',
				mode: 'float',
			})

			document.querySelector('.toggleMobileBar').addEventListener('click', (event) => {
				window.bartender.toggle('mobileBar', event.target)
			})

			document.querySelector('.closeMobileBar').addEventListener('click', (event) => {
				window.bartender.close()
			})

			window.bartender.addPushElement('.toggleMobileBar', {
				positions: [
					'right',
				],
			})
		})

		// Init mobile menu
		const mobileMenu = document.getElementById('mobile-menu')
		new boringmenu({
			selectors: {
				menu: '.menu-mobile__list--level-1',
			},
			classes: {
				item: 'menu-mobile__item',
				itemActive: 'menu-mobile__item--current',
				itemParent: 'menu-mobile__item--parent',
				toggle: 'menu-mobile__toggle',
				toggleTextContainer: 'sr-only',
			},
			labels: {
				'menu.open': mobileMenu
					? mobileMenu.getAttribute('data-labels-open')
					: 'open',
				'menu.close': mobileMenu
					? mobileMenu.getAttribute('data-labels-close')
					: 'close',
			},
			icons: {
				'menu.open': 'icon-open',
				'menu.close': 'icon-close',
			},
		})

		// Initialize
		this.init(options)
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @param {Object} options Options for the class.
	 * @return {Object}
	 */
	init(options = {}) {
		// Merge user options to the defaults
		this.options = {
			responsiveTables: {
				selector: 'main table',
			},
			imageLinks: {
				parentSelector: 'main',
			},
			...options,
		}

		// Call individual init methods
		this.initResponsiveTables()
		this.initSkipLinks()
		this.initImageLinks()
		this.initAccordions()
		this.initCopyLinkToClipboard()
		this.initProductSort()

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('site-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	/**
	 * Initialize responsive tables
	 *
	 * Finds content tables and wraps them with div.table-wrapper.
	 */
	initResponsiveTables() {
		document
			.querySelectorAll(this.options.responsiveTables.selector)
			.forEach((table) => {
				if (!table.closest('.table-wrapper')) {
					const tableWrapper = document.createElement('div')
					tableWrapper.classList.add('table-wrapper')
					tableWrapper.classList.add('overflow-x-auto')
					table.parentNode.insertBefore(tableWrapper, table)
					tableWrapper.appendChild(table)
				}
			})
	}

	/**
	 * Initialize skip links
	 *
	 * Finds skip links and enhances their behaviour for various screen readers and mobile devices.
	 */
	initSkipLinks() {
		const skipLinks = document.querySelectorAll(
			'.skip-link:not([data-skip-link])'
		)
		if (skipLinks.length) {
			const skipToBlur = (event) => {
				if (event.target.getAttribute('data-tabindex')) {
					event.target.removeAttribute('tabindex')
					event.target.removeAttribute('data-tabindex')
				}
			}
			skipLinks.forEach((skipLink) => {
				skipLink.setAttribute('data-skip-link', true)
				skipLink.addEventListener('click', (event) => {
					const skipTo = document.getElementById(
						event.target.href.split('#')[1]
					)
					if (skipTo && skipTo.getAttribute('tabindex') != '-1') {
						event.preventDefault()
						skipTo.setAttribute('tabindex', '-1')
						skipTo.setAttribute('data-tabindex', true)
						skipTo.addEventListener('blur', skipToBlur)
						skipTo.addEventListener('focusout', skipToBlur)
						skipTo.focus()
					}
				})
			})
		}
	}

	/**
	 * Initialize image links
	 */
	initImageLinks() {
		// Parent of image links
		let parentNode = document.querySelector(
			this.options.imageLinks.parentSelector
		)
		if (!parentNode) return

		// Add glightbox class to image links
		parentNode
			.querySelectorAll(
				'a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]'
			)
			.forEach((link) => {
				if (!link.classList.contains('glightbox')) {
					if (
						!link.getAttribute('data-title') &&
						!link.getAttribute('data-glightbox')
					) {
						let figcaption = link.parentNode.querySelector('figcaption')
						if (figcaption) {
							let caption = figcaption ? figcaption.textContent : ''
							link.setAttribute('data-title', caption)
						}
					}
					link.classList.add('glightbox')
				}
			})

		// Initialize GLightbox
		if (parentNode.querySelector('.glightbox')) {
			window.glightbox = Glightbox()
		}
	}

	/**
	 * Initialize accordions
	 */
	initAccordions () {

		// Find accordion headers
		const accordionHeaders = document.querySelectorAll('[data-accordion-header]')
		if (!accordionHeaders.length) return

		// Add expand event to accordion buttons
		Array.prototype.forEach.call(accordionHeaders, accordionHeader => {
			let target = document.getElementById(accordionHeader.getAttribute('aria-controls'))
			if (target) {
				accordionHeader.onclick = () => {
					let expanded = accordionHeader.getAttribute('aria-expanded') == 'true' || false
					accordionHeader.setAttribute('aria-expanded', !expanded)
					target.hidden = expanded
				}
			}
		})

		// Open accordion if hash matches an accordion or an anchor inside an accordion
		if (window.location.hash) {
			let hash = window.location.hash.replace('#', '')
			this.openAccordionByHash(hash)
		}
		document.querySelectorAll('a[href^="#"]').forEach(anchor => {
			anchor.addEventListener('click', event => {
				let hash = anchor.getAttribute('href').replace('#', '')
				if (this.openAccordionByHash(hash)) {
					event.preventDefault()
				}
			})
		})
	}

	/**
	 * Open accordion by hash
	 *
	 * @param {String} hash Hash to open accordion
	 */
	openAccordionByHash(hash) {

		if (!hash) return

		let accordionHeader = document.querySelector(`[data-accordion-header][aria-controls="${hash}"]`)
		if (accordionHeader) {
			accordionHeader.setAttribute('aria-expanded', true)
			document.getElementById(hash).hidden = false
			window.setTimeout(() => {
				accordionHeader.scrollIntoView()
			}, 100)
			return accordionHeader
		}

		let anchor = document.getElementById(hash)
		if (!anchor) {
			anchor = document.querySelector(`a[name="${hash}"]`)
		}
		if (anchor && anchor.closest('.accordion-block__section')) {
			const accordionSection = anchor.closest('.accordion-block__section')
			accordionHeader = accordionSection.parentElement.querySelector('[data-accordion-header]')
			if (accordionHeader) {
				accordionHeader.setAttribute('aria-expanded', true)
				document.getElementById(accordionHeader.getAttribute('aria-controls')).hidden = false
				window.setTimeout(() => {
					accordionHeader.scrollIntoView()
				}, 100)
				return accordionHeader
			}
		}

		return false
	}

	/**
	 * Initialize copy link to clipboard
	 */
	initCopyLinkToClipboard() {
		document.querySelectorAll('.js-copy-link').forEach((link) => {
			link.addEventListener('click', (event) => {
				event.preventDefault()
				link.classList.remove('js-copy-link--copied')
				const copyIcon = link.querySelector('.js-copy-link__icon')
				if (copyIcon) {
					link.querySelector('.js-copy-link__icon').removeAttribute('hidden')
					link.querySelector('.js-copy-link__icon--copied').setAttribute('hidden', true)
				}
				navigator.clipboard.writeText(link.href).then(() => {
					link.classList.add('js-copy-link--copied')
					if (copyIcon) {
						copyIcon.setAttribute('hidden', true)
						link.querySelector('.js-copy-link__icon--copied').removeAttribute('hidden')
					}
					if (link.getAttribute('data-copy-link-notice')) {
						window.setTimeout(() => {
							window.alert(link.getAttribute('data-copy-link-notice'))
						}, 100)
					}
				})
			})
			link.addEventListener('blur', () => {
				link.classList.remove('js-copy-link--copied')
				const copyIcon = link.querySelector('.js-copy-link__icon')
				if (copyIcon) {
					link.querySelector('.js-copy-link__icon').removeAttribute('hidden')
					link.querySelector('.js-copy-link__icon--copied').setAttribute('hidden', true)
				}
			})
		})
	}

	/**
	 * Initialize product sort
	 */
	initProductSort() {
		document.querySelectorAll('.product-sort').forEach((sort) => {
			sort.addEventListener('change', (event) => {
				const form = sort.closest('form')
				if (form) {
					form.submit()
				}
			})
		})
	}
}
